// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-appliances-js": () => import("./../../../src/pages/kitchen-appliances.js" /* webpackChunkName: "component---src-pages-kitchen-appliances-js" */),
  "component---src-pages-refrigerators-js": () => import("./../../../src/pages/refrigerators.js" /* webpackChunkName: "component---src-pages-refrigerators-js" */),
  "component---src-pages-semi-auto-js": () => import("./../../../src/pages/semi-auto.js" /* webpackChunkName: "component---src-pages-semi-auto-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-pages-washing-machine-1-js": () => import("./../../../src/pages/washing-machine-1.js" /* webpackChunkName: "component---src-pages-washing-machine-1-js" */),
  "component---src-pages-washing-machines-js": () => import("./../../../src/pages/washing-machines.js" /* webpackChunkName: "component---src-pages-washing-machines-js" */)
}

